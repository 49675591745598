import { merge } from "lodash";

let trans = {};
const requireTrans = require.context(
  ".",
  true,
  /^((?!default|other|$vuetify).)*.json$/
);

requireTrans.keys().forEach((fileName) => {
  const transFile = requireTrans(fileName);
  const keyTrans = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
  trans = merge(trans, { [keyTrans]: transFile });
});

export default trans;
